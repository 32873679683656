ionic-selectable-modal {
  .not-found {
    padding: 16px;
  }
}

ion-app,
.ion-page {
  $max-width: 768px;
  $bgOpacity: 0.25;
  align-items: center;

  ion-header,
  ion-footer {
    max-width: $max-width;
    margin: 0 auto;

    ion-toolbar {
      --background: var(--ion-color-primary);
      color: black;
    }

    .opacity-toolbar {
      --background: rgba(#e6e6e6, 0.62);
    }
  }

  .answer-modal > .ion-page {
    align-items: inherit;
  }

  ion-content {
    --background: transparent;

    &.max-width {
      max-width: $max-width;

      &::part(background) {
        background: white;
        opacity: $bgOpacity;
      }
    }
  }

  google-map {
    height: 1000%;
    width: 100%;

    .gmnoprint,
    .gm-style-cc {
      display: none;
    }
  }

  .map-container {
    height: 100% !important;
    width: 100% !important;
    position: static !important;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    ion-content {
      &.max-width {
        &::part(background) {
          background: none;
          opacity: 1;
        }
      }
    }
  }
}

ion-modal.modal-default.show-modal ~ ion-modal.modal-default {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

ion-modal {
  .ion-page {
    align-items: normal;
  }

  &.alert-modal {
    --width: 350px;
    --max-width: 768px;
    --height: auto;

    ion-header {
      &:after {
        height: 0;
      }

      ion-toolbar {
        --background: transparent;
      }
    }
  }
}

.modal-transparent-header {
  ion-header {
    &:after {
      height: 0;
    }

    ion-toolbar {
      --background: transparent;
    }
  }
}

.full-height-modal {
  --height: 90vh;
  --width: calc(100% - 10vw);
  --max-width: calc(768px - 10vw);
}
ion-modal.map-modal {
  --width: 600px !important;
  --max-width: 600px !important;
}
.close-btn::part(native) {
  padding: 0;
  width: 20px;
  height: 20px;
}

.ion-no-shadow {
  box-shadow: none;
}

.ion-no-border {
  border: none !important;
  border-radius: 0;
}

/* Global Discord Modal Styling */
ion-modal.discord-modal {
  --width: 320px;
  --max-width: 320px;
  --height: auto;
  --max-height: 95%;
  --border-radius: 16px;
  --background: transparent;
  --box-shadow: none;
  --backdrop-opacity: 0.7;
  --backdrop-filter: blur(5px);

  &::part(content) {
    border-radius: 16px;
    overflow: hidden;
    background: white;
  }
}
