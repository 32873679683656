/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "styles/general";
@import "styles/bubbles";

@import "styles/components-overrides";

[autoTranslate] {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  max-width: 100%;
  display: inline-block;
}

.ion-text-wrap[autoTranslate] {
  white-space: normal;
}

app-language-selector {
  display: block;
  z-index: 1000;

  ion-header {
    position: relative;
  }
}

ion-app {
  display: flex;
  flex-direction: column;
}

ion-content {
  flex: 1;
}

// Add styles for non-English text in buttons
html:not([lang="en"]) {
  ion-button,
  button,
  .button,
  [role="button"] {
    font-size: 0.9em; // Make text 90% of original size
    line-height: 1.2; // Tighter line height

    // If text still overflows, add ellipsis
    &.text-wrap-none {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  // For specific cases where text might be extra long
  ion-button.small-text,
  button.small-text {
    font-size: 0.8em; // Even smaller for buttons with .small-text class
  }
}

// Optional: Add specific adjustments for certain languages that tend to have longer words
html[lang="de"],  // German
html[lang="fi"],  // Finnish
html[lang="nl"] {
  // Dutch
  ion-button,
  button,
  .button,
  [role="button"] {
    font-size: 0.85em; // Slightly smaller for languages with longer words
  }
}

// Add specific styles for filter buttons
.aside-btn {
  height: 2.5em; // Make button height smaller
  font-size: 0.85em; // Make text smaller

  &.button-clear {
    --padding-start: 0.5em;
    --padding-end: 0.5em;
  }
}

// Make them even smaller in non-English languages
html:not([lang="en"]) {
  .aside-btn {
    font-size: 0.8em; // Even smaller text for translations
    height: 2.3em; // Slightly smaller height

    // Reduce padding to prevent text overflow
    --padding-start: 0.4em;
    --padding-end: 0.4em;
  }
}

// Adjust padding if text is too long
.aside-btn.text-wrap-none {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px; // Limit maximum width
}

// Add styles for fab buttons
ion-fab-button.item-fab {
  --size: 65px; // Make button bigger (default is 56px)
  font-size: 0.75em; // Make text smaller
  text-transform: uppercase;

  &.ion-color-warning {
    --ion-color-contrast: var(--ion-color-warning-contrast);
  }
}

// Make text even smaller in non-English languages
html:not([lang="en"]) {
  ion-fab-button.item-fab {
    font-size: 0.7em;
    --size: 70px; // Slightly bigger for translated text
  }
}

/* ===== EVENT MODAL STYLES ===== */
/* Styles for both edit events and external event modals */
ion-modal.edit-events-modal,
ion-modal.external-event-modal {
  --width: 500px;
  --max-width: 90%;
  --height: auto;
  --max-height: 95%;
  --border-radius: 16px;
  --background: transparent;
  --box-shadow: none;
  --backdrop-opacity: 0.7;
  --backdrop-filter: blur(5px);
  --overflow: scroll; /* Ensure scrolling is enabled */

  .modal-wrapper {
    position: relative;
    height: auto;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    overflow-y: scroll; /* Change from auto to scroll to ensure scrolling works */
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }
  }

  .ion-page {
    overflow-y: scroll; /* Change from auto to scroll */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }
  }

  /* Enhanced scrolling for modals */
  .modal-wrapper {
    overflow-y: scroll; /* Change from auto to scroll */

    .ion-page {
      overflow-y: scroll; /* Change from auto to scroll */

      ion-content {
        --overflow: scroll; /* Change from auto to scroll */
      }

      .scroll-content {
        overflow-y: scroll; /* Change from auto to scroll */
      }

      app-item-form {
        height: auto;
        overflow-y: scroll; /* Change from auto to scroll */

        ion-header {
          height: auto;
          overflow: visible;
        }

        .form-wrap {
          height: auto;
          overflow-y: scroll; /* Change from auto to scroll */
          padding-bottom: 20px;
          padding-left: 16px;
          padding-right: 16px;
        }
      }

      form {
        max-height: none;
        overflow-y: visible;
        padding-left: 16px;
        padding-right: 16px;
      }

      .template {
        max-height: none;
        overflow-y: scroll; /* Change from auto to scroll */
      }

      /* Consistent icon alignment */
      ion-item {
        ion-icon[slot="start"] {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-width: 22px;
          margin-right: 8px;
          vertical-align: middle;
        }

        ion-datetime-button ion-icon,
        ion-select ion-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          min-width: 22px;
          margin-right: 8px;
          vertical-align: middle;
        }
      }
    }
  }
}

/* Mobile specific styles */
@media (max-width: 768px) {
  ion-modal.edit-events-modal,
  ion-modal.external-event-modal {
    --width: 100%;
    --max-width: 100%;
    --border-radius: 16px 16px 0 0;

    .modal-wrapper {
      width: 100%;
      max-width: 100%;
      border-radius: 16px 16px 0 0;
    }
  }
}

/* ===== MOBILE OPTIMIZATIONS ===== */
@media (max-width: 576px) {
  /* Mobile-specific modal styles */
  .edit-events-modal,
  .external-event-modal {
    --width: 100% !important;
    --height: 100% !important;
    --border-radius: 0 !important;

    .modal-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      border-radius: 0;
    }

    ion-header {
      ion-toolbar {
        --min-height: 48px !important;

        ion-title {
          font-size: 16px !important;
          padding: 0 8px !important;
        }

        ion-buttons {
          min-height: 36px !important;

          ion-button {
            --padding-start: 4px !important;
            --padding-end: 4px !important;
            font-size: 13px !important;

            ion-icon {
              font-size: 18px !important;
            }
          }
        }
      }
    }

    ion-content {
      --padding-start: 8px !important;
      --padding-end: 8px !important;
      --padding-top: 8px !important;
      --padding-bottom: 8px !important;
    }
  }
}

/* ===== TEXT CUTOFF FIXES ===== */
/* Fix for text cutoff in radio buttons and list items */
.edit-events-modal,
.external-event-modal {
  /* Hide scrollbars but maintain scroll functionality */
  .modal-wrapper {
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE and Edge */
    overflow-y: auto !important;

    &::-webkit-scrollbar {
      display: none !important; /* Chrome, Safari, Opera */
    }
  }

  /* Ensure content is scrollable without showing scrollbars */
  ion-content {
    --overflow: auto !important;

    &::part(scroll) {
      scrollbar-width: none !important; /* Firefox */
      -ms-overflow-style: none !important; /* IE and Edge */

      &::-webkit-scrollbar {
        display: none !important; /* Chrome, Safari, Opera */
      }
    }
  }

  /* Fix for the ion-page to prevent double scrolling */
  .ion-page {
    position: relative !important;
    contain: content !important;
    overflow-y: auto !important;
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE and Edge */

    &::-webkit-scrollbar {
      display: none !important; /* Chrome, Safari, Opera */
    }
  }

  ion-radio-group {
    width: 100%;

    ion-item {
      --min-height: auto;
      --inner-padding-end: 0;
      --inner-padding-start: 0;
      --padding-start: 0;
      --padding-end: 0;
      margin-bottom: 8px;

      ion-label {
        white-space: normal !important;
        overflow: visible !important;
        text-overflow: clip !important;
        margin: 0;
        padding: 8px 0;
        font-size: 14px;
        line-height: 1.4;
      }

      ion-radio {
        margin-right: 12px;
        align-self: flex-start;
        margin-top: 8px;
      }
    }
  }

  /* Fix for checkboxes */
  ion-checkbox {
    margin-right: 12px;
    align-self: flex-start;
    margin-top: 8px;
  }

  /* Fix for all list items */
  ion-list ion-item {
    --inner-padding-end: 0;

    ion-label {
      white-space: normal !important;
      overflow: visible !important;
      text-overflow: clip !important;
    }
  }
}

/* Mobile-specific text cutoff fixes */
@media (max-width: 576px) {
  .edit-events-modal,
  .external-event-modal {
    ion-radio-group {
      ion-item {
        padding: 2px 0;

        ion-label {
          font-size: 14px !important;
          padding: 4px 0;
        }

        ion-radio {
          margin-right: 8px;
          margin-top: 4px;
        }
      }
    }

    /* Fix for radio buttons and checkboxes */
    ion-radio-group,
    ion-checkbox-group {
      ion-item {
        --min-height: auto;
        padding: 4px 0;

        ion-label {
          white-space: normal !important;
          overflow: visible !important;
          text-overflow: clip !important;
          font-size: 14px !important;
          line-height: 1.4 !important;
          padding: 6px 0;
        }

        ion-radio,
        ion-checkbox {
          margin-right: 10px;
          align-self: flex-start;
          margin-top: 6px;
        }
      }
    }

    /* Fix for all list items with text */
    ion-list ion-item {
      ion-label {
        white-space: normal !important;
        overflow: visible !important;
        text-overflow: clip !important;
      }
    }

    /* Fix for select options */
    ion-select-option,
    .select-interface-option {
      white-space: normal !important;
      overflow: visible !important;
      text-overflow: clip !important;
    }

    /* Fix for alert options */
    .alert-radio-label,
    .alert-checkbox-label {
      white-space: normal !important;
      overflow: visible !important;
      text-overflow: clip !important;
    }
  }
}

// Event form specific styles
app-event-form {
  width: 100% !important;
  max-width: 1400px !important;
  margin: 0 auto;
  display: block;

  form {
    width: 100% !important;
    max-width: 1400px !important;
    padding: 0 16px !important;
  }

  .form-wrap {
    width: 100% !important;
    max-width: 1400px !important;
    padding: 0 16px !important;
  }

  ion-item {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  @media screen and (max-width: 768px) {
    form,
    .form-wrap {
      padding: 0 12px !important;
    }

    ion-label,
    ion-input,
    ion-textarea,
    ion-select,
    ion-datetime-button,
    ion-note {
      font-size: 0.9em !important;
    }

    ion-icon {
      font-size: 20px !important;
    }
  }
}

/* ===== DATETIME MODAL STYLES ===== */
/* Styles for datetime picker modals */
ion-modal.datetime-modal {
  --width: 350px !important;
  --max-width: 350px !important;
  --height: auto !important;
  --max-height: 500px !important;
  --border-radius: 16px !important;

  ion-datetime {
    width: 320px !important;
    max-width: 100% !important;

    // Fix calendar display
    &::part(calendar) {
      width: 100% !important;
      max-width: 320px !important;
    }

    // Fix day display
    &::part(calendar-day) {
      width: 40px !important;
      max-width: 40px !important;
      height: 40px !important;
    }
  }
}

/* ===== SCROLLBAR FIXES FOR WINDOWS ===== */
/* Hide scrollbars but maintain scroll functionality for all modals */
ion-modal {
  .modal-wrapper {
    overflow-y: scroll !important; /* Change from auto to scroll */
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE and Edge */

    &::-webkit-scrollbar {
      display: none !important; /* Chrome, Safari, Opera */
    }
  }

  ion-content {
    --overflow: scroll !important; /* Change from auto to scroll */

    &::part(scroll) {
      overflow-y: scroll !important; /* Change from auto to scroll */
      scrollbar-width: none !important; /* Firefox */
      -ms-overflow-style: none !important; /* IE and Edge */

      &::-webkit-scrollbar {
        display: none !important; /* Chrome, Safari, Opera */
      }
    }
  }

  .ion-page {
    overflow-y: scroll !important; /* Change from auto to scroll */
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE and Edge */

    &::-webkit-scrollbar {
      display: none !important; /* Chrome, Safari, Opera */
    }
  }

  /* Hide scrollbars for any scrollable elements inside modals */
  ion-content,
  ion-scroll,
  .scrollable,
  .scroll-content,
  [overflow-y="auto"],
  [overflow-y="scroll"],
  ion-list,
  ion-item-group,
  ion-reorder-group,
  ion-list-header,
  ion-item-divider,
  ion-virtual-scroll {
    overflow-y: scroll !important; /* Change from auto to scroll */
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE and Edge */

    &::-webkit-scrollbar {
      display: none !important; /* Chrome, Safari, Opera */
    }
  }
}

/* Specific fix for edit-events-modal */
.edit-events-modal {
  .modal-wrapper {
    overflow-y: scroll !important; /* Change from auto to scroll */
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE and Edge */

    &::-webkit-scrollbar {
      display: none !important; /* Chrome, Safari, Opera */
    }
  }

  app-edit-events,
  app-event-form,
  .edit-events-form,
  form,
  ion-content,
  .ion-page {
    overflow-y: scroll !important; /* Change from auto to scroll */
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE and Edge */

    &::-webkit-scrollbar {
      display: none !important; /* Chrome, Safari, Opera */
    }
  }
}

/* Curvy modal styling */
ion-modal.curvy-modal {
  --border-radius: 16px;

  .modal-wrapper {
    border-radius: 16px;
    overflow: hidden;
  }

  ion-header {
    border-radius: 16px 16px 0 0;
  }

  ion-footer {
    border-radius: 0 0 16px 16px;
  }

  ion-item {
    --border-radius: 8px;
    margin-bottom: 8px;
  }

  ion-button {
    --border-radius: 10px;
  }

  ion-input,
  ion-textarea,
  ion-select {
    --border-radius: 8px;
  }
}

/* Mobile specific styles for curvy modal */
@media (max-width: 768px) {
  ion-modal.curvy-modal {
    --border-radius: 16px 16px 0 0;

    .modal-wrapper {
      border-radius: 16px 16px 0 0;
    }

    ion-footer {
      border-radius: 0;
    }
  }
}

/* Scrollable modal styling */
ion-modal.scrollable-modal {
  --overflow: scroll !important;

  .modal-wrapper {
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch !important;
    touch-action: pan-y !important;
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE and Edge */

    &::-webkit-scrollbar {
      display: none !important; /* Chrome, Safari, Opera */
    }
  }

  .ion-page {
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch !important;
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE and Edge */

    &::-webkit-scrollbar {
      display: none !important; /* Chrome, Safari, Opera */
    }
  }

  ion-content {
    --overflow: scroll !important;

    &::part(scroll) {
      overflow-y: scroll !important;
      -webkit-overflow-scrolling: touch !important;
      scrollbar-width: none !important; /* Firefox */
      -ms-overflow-style: none !important; /* IE and Edge */

      &::-webkit-scrollbar {
        display: none !important; /* Chrome, Safari, Opera */
      }
    }
  }

  /* Fix for 3 dots menu */
  ion-header,
  .modal-header {
    position: sticky !important;
    top: 0 !important;
    z-index: 1000 !important;
    background: var(--ion-background-color) !important;
  }

  /* Ensure action buttons are accessible */
  .action-buttons,
  .event-actions,
  ion-buttons[slot="end"],
  .event-actions-top {
    z-index: 1001 !important;
    position: relative !important;
  }

  /* Ensure 3 dots menu opens above content */
  ion-popover {
    --backdrop-opacity: 0.3 !important;
    --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2) !important;
    z-index: 1002 !important;
  }
}

/* Mobile specific styles for scrollable modal */
@media (max-width: 768px) {
  ion-modal.scrollable-modal {
    .modal-wrapper {
      -webkit-overflow-scrolling: touch !important;
      touch-action: pan-y !important;
    }

    ion-content {
      --overflow-y: scroll !important;
      --webkit-overflow-scrolling: touch !important;
    }
  }
}
